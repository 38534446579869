import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "name",
    label: "Client Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address_name",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "emailId",
    label: "Email",
    align: "left",
    minWidth: 25,
  },
  {
    id: "gstNumber",
    label: "GST Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "occupierName",
    label: "Occupier Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "vendorCode",
    label: "Vendor Code",
    align: "left",
    minWidth: 40,
  },
];

const ClientsList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);
  const [searched, setSearched] = React.useState(false);
  const urlPath = useLocation();

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Client Name": "name",
        "GST Number": "gstNumber",
        "Occupier Name": "occupierName",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT c.*, a.address AS address_name FROM clients c LEFT JOIN addresses a ON a.id = CAST(SUBSTRING_INDEX(c.address, ',', 1) AS UNSIGNED)  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM clients  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `clients/${id}`)
      .then((res) => {
        toast("Shop is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const search = () => {
  sessionStorage.setItem("searchBy", searchBy);
  sessionStorage.setItem("searchKey", searchKey);
  sessionStorage.setItem("searched", "true");
  sessionStorage.setItem("currentPath", urlPath.pathname);
  setSearched(true);
  fetchFinalData();
  getTotalRows();
};

const clearSearch = () => {
  sessionStorage.removeItem("searchBy");
  sessionStorage.removeItem("searchKey");
  sessionStorage.removeItem("searched", searched);
  sessionStorage.removeItem("currentPath");
  setSearchBy("Client Name");
  setSearchKey("");
  setSearched(false);
};

const storedSearchBy = sessionStorage.getItem("searched");
useEffect(() => {
  if(storedSearchBy){
    setSearched(true);
  }
}, [storedSearchBy]);

  
  useEffect(() => {
    getTotalRows();
    fetchFinalData();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched==false) {
      fetchFinalData();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Client",
      link: true,
      linkTo: (row) => `/editClient/${row.id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Client",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{px:2}}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/editClient"
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              justifyContent: "flex-end",
              gap:"20px"
            }}
          >
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
   
              size="small"
              id="combo-box-demo"
              options={
                _userType != 3
                  ? [
                    { key: "name", label: "Client Name" },
                    { key: "gstNumber", label: "GST Number" },
                    { key: "occupierName", label: "Occupier Name" },
                  
                  ]
                  : [{ key: "occupierName", label: "Occupier Name" }]
              }
              value={searchBy}
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
                setSearched(false);
              }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
}

export default ClientsList