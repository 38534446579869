import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Toolbar,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import CommonForm from "../../utils/components/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

const EditForms = () => {
  const { reportID, templateID } = useParams();
  const navigate = useNavigate();
  const [inputFields, setInputFields] = React.useState([]);
  const [inputStates, setInputStates] = React.useState(() => {
    if (templateID == 13) {
      return {
        // typeIdentificationNumber: "Sr No:-\nCapacity:-\nLocation:-\nMake:-",
        // equipmentId: "Lifting machine",
        // enclosureOfHoist: "NA",
        // landingGates: "NA",
        // interlocksOnLanding: "NA",
        // gateFastings: "NA",
        // platformFittings: "NA",
        // overrunningDevices: "NA",
        // suspensionRopes: "NA",
        // safetyGear: "NA",
        // breaks: "NA",
        // spurGaring: "NA",
        // electronicalEquipment: "NA",
        // defectDescription: "Nil",
        // dateOfExamination: `Previously tested by us on dt.`,
        // dateOfAnnealing : "NA",
        // renewalInformation : "Nil",
        // mainatance: "Not Applicable",
        // repairsrenewals: "Nil",
        // defectDescription:"Disclaimer: This certificate neither gives any performance guarantee nor ensure against any failure of the equipment in future. It indicates only the present status of the equipment on the day of the inspection based on the test carried out. Daily inspection and ensure safety precautions is occupier responsibility."
      };
    } else {
      return {
        // distinctiveNumber: "Sr No:-\nCapacity:-\nLocation:-",
        // thicknessOfWall: "Shell Thickness:-\nDish Thickness:-",
        // whomCarriedOut: "Tested by us",
        // examinationPurpose: "Unlagged",
        // externalExamination : "Externally examined for pitting, corrosion etc & found O.K.",
        // internalExamination : "Inaccessible",
        // hydraulicTestExamination : "Not Due",
        // ultrasonicTest : "Ultrasonically tested & Found O.K.\nShell Thickness:-\nDish Thickness:-",
        // vessel : "O.K.",
        // piping : "Checked & found O.K.",
        // safetyValve: "Checked & found O.K.",
        // stopValve : "Checked & found O.K.",
        // reducingValve:"Not Applicable",
        // additinalSafety:"Not Applicable",
        // otherDevices:"Not Applicable",
        // appliancesMaintaince:"Not Applicable",
        // gasHolder: "Not Applicable",
        // pressureGauges:"Checked & found O.K.",
        // repairsIfAnyAndPeriod:"Nil",
        // specifyReducedWorkingPressure:"Not Applicable",
        // conditionSubject:"Condition is satisfactory to be operated in safe working pressure.",
        // internalTestDate:"Inaccessible",
        // internalExaminationDueDate:"Inaccessible",
        // lastInternalExamination:"Inaccessible",
        // equipmentId: "Pressure Vessel",
        safeWorkingPressure9: "7.00 Kg/cm²",
        // conditionSubject:"Disclaimer: This certificate neither gives any performance guarantee nor ensure against any failure of the equipment in future. It indicates only the present status of the equipment on the day of the inspection based on the test carried out. Daily inspection and ensure safety precautions is occupier responsibility."
      };
    }
  });
  const [loading, setLoading] = React.useState(false);
  const [reportList, setReportList] = React.useState([]);
  const [previousReport, setPreviousReport] = React.useState(null);
  const [nextReport, setNextReport] = React.useState(null);

  useEffect(() => {
    setInputStates((prevState) => {
      const updates = {};
      if (inputStates?.dateOfExamination) {
        const dateOfExamination = inputStates.dateOfExamination;
        updates.numberOfCertification = dateOfExamination;
      }
      return { ...prevState, ...updates };
    });
  }, [inputStates?.dateOfExamination]);

  useEffect(() => {
    setInputStates((prevState) => {
      const updates = {};
      if (inputStates?.inspectionDate) {
        const inspectionDate = inputStates.inspectionDate
          ? moment(inputStates.inspectionDate)
          : null;
        updates.certificateValidity =
          inspectionDate && inputStates?.testingFrequency
            ? inspectionDate
                .add(inputStates?.testingFrequency.split(" ")?.[0], "M")
                .subtract(1, "days")
                .format("YYYY-MM-DD")
            : inputStates.inspectionDate;
      }
      return { ...prevState, ...updates };
    });
  }, [inputStates.testingFrequency, inputStates.inspectionDate]);

  useEffect(() => {
    setInputStates((prevState) => {
      if (prevState?.reportDate) {
        return {
          ...prevState,
          inspectionDate: prevState.reportDate,
        };
      }
      return prevState;
    });
  }, [inputStates?.reportDate]);

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const submitReport = async () => {
    try {
      const formConfigJson = JSON.stringify(inputStates);

      if (formConfigJson) {
        const reportData =
          reportID < 1
            ? {
                templateId: templateID,
                templateValues: formConfigJson,
                // reportNo: reportDetails?.reportNo,
                // qrCodeNo: reportDetails?.qrCodeNo,
              }
            : {
                templateValues: formConfigJson,
                // reportNo: reportDetails?.reportNo,
                // qrCodeNo: reportDetails?.qrCodeNo,
              };

        const reportEndpoint =
          reportID < 1
            ? BASE_URL + "reportValues"
            : BASE_URL + `reportValues/${reportID}`;

        const reportResponse = await (reportID < 1
          ? axiosWithToken.post(reportEndpoint, reportData)
          : axiosWithToken.patch(reportEndpoint, reportData));

        toast(
          reportResponse.status === 200
            ? "Report created/updated successfully!"
            : "Failed to create/update Report."
        );
        if (reportResponse.status === 200) {
          setTimeout(refresh, 500);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong!");
    }
  };

  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplate = (id) => {
    try {
      setLoading(true);
      axiosWithToken
        .get(BASE_URL + `templates/${id}`)
        .then((res) => {
          let data = res.data[0];
          axios.get(data?.url).then((respose) => {
            setInputFields(respose.data);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  const fetchAddress = (id) => {
    try {
      let SQLQuery = {
        query: `SELECT a.address, c.occupierName FROM addresses a left JOIN clients c ON SUBSTRING_INDEX(c.address, ',', 1) = a.id WHERE c.id = ${id}`,
      };
      setLoading(true);
      axiosWithToken
        .post(BASE_URL + `dynamic`, SQLQuery)
        .then((res) => {
          let data = res.data[0];
          setInputStates((prevState) => ({
            ...prevState,
            addressFactory: data?.address,
            Occupier: data?.occupierName,
          }));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log("Failed to fetch checklist template: ", e);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const response = await axiosWithToken.post(BASE_URL + `dynamic`, {
          query: "select id , templateId from reportValues order by id desc",
        });
        const data = response.data?.map(
          (item) => `${item.id},${item.templateId}`
        );
        setReportList(data);
      } catch (error) {
        console.error("Failed to fetch report list:", error);
      }
    };

    fetchReportList();
  }, []);

  const handlePrev = () => {
    if (previousReport) {
      const [prevReportID, prevTemplateID] = previousReport.split(",");
      navigate(`/editForms/${prevReportID}/${prevTemplateID}`);
    }
  };

  const handleNext = () => {
    if (nextReport) {
      const [nextReportID, nextTemplateID] = nextReport.split(",");
      navigate(`/editForms/${nextReportID}/${nextTemplateID}`);
    }
  };

  useEffect(() => {
    if (reportList.length > 0) {
      const searchString = `${reportID},${templateID}`;
      const index = reportList.indexOf(searchString);
      const prev = index > 0 ? reportList[index - 1] : null;
      const next = index < reportList.length - 1 ? reportList[index + 1] : null;
      setPreviousReport(prev);
      setNextReport(next);
    }
  }, [reportList, reportID, templateID]);
  useEffect(() => {
    if (inputStates?.factoryId) fetchAddress(inputStates?.factoryId);
  }, [inputStates?.factoryId]);

  useEffect(() => {
    if (reportID) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [reportID]);

  useEffect(() => {
    if (templateID > 0) {
      fetchTemplate(templateID);
    }
    if (reportID > 0) {
      fetchformReport(reportID);
    }
  }, [reportID, templateID]);

  return (
    <>
      <Paper sx={{ mt: 2, p: 4 }}>
        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
          isTemplateForm={true}
          params={reportID}
        />

        <Box sx={{ display: "Flex", gap: "20px", justifyContent: "flex-end" }}>
          <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={submitReport}
            >
              {reportID > 0 ? "Update" : "Save"}
            </Button>
          </Toolbar>
          {reportID != 0 && (
            <>
              <Toolbar
                style={{
                  padding: "0px",
                  overflow: "auto",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ m: 0 }}
                  onClick={handlePrev}
                  disabled={!previousReport}
                >
                  Next
                </Button>
              </Toolbar>
              <Toolbar
                style={{
                  padding: "0px",
                  overflow: "auto",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ m: 0 }}
                  onClick={handleNext}
                  disabled={!nextReport}
                >
                  Prev
                </Button>
              </Toolbar>
            </>
          )}
        </Box>
        <Modal
          open={loading}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100vh",
              bgcolor: "background.paper",
              border: "2px solid cadetblue",
              padding: "30px",
              textAlign: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <CircularProgress color="secondary" size={200} />
          </Box>
        </Modal>
      </Paper>
    </>
  );
};

export default EditForms;
