import React, { useState, useEffect } from 'react';
import { Grid, TextField, Autocomplete, Typography, Button, Input } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BASE_URL } from '../../global';
import { ToastContainer, toast } from "react-toastify";
import axiosWithToken from './axiosTokenConfig'; 

const CommonForm = ({ inputStates, updateInputObject, inputFields,isTemplateForm, params }) => {
  const [fetchedOptions, setFetchedOptions] = useState({});

  useEffect(() => {
    const fetchOptions = async (tableName) => {
      try {
        const response = await axiosWithToken.get(BASE_URL + tableName);
        setFetchedOptions(prevOptions => ({
          ...prevOptions,
          [tableName]: response.data,
        }));
      } catch (error) {
        toast.error("Something Went Wrong!");
      }
    };

    inputFields.forEach(({ type, options }) => {
      if (type === 'Autocomplete' && options && !Array.isArray(options)) {
        fetchOptions(options);
      }
    });
  }, [inputFields]);

  const handleFileUpload12 = (event, stateKey) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);
    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status == 200) {
          updateInputObject(stateKey, res.data);
          toast.success("File Uploaded Successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {

          toast.error("Something went wrong");

      });
  };

  const renderInputField = (fieldConfig, index) => {
    const { type, label, stateKey, valueKey, options, getOptionLabelKey } = fieldConfig;
    const selectedValue = inputStates[stateKey] || '';

    switch (type) {
      case 'Autocomplete':
        return (<>
        {isTemplateForm && ( <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index} style={{textAlign:"left"}} >
        <b>{label}</b>
        </Grid>
        )}
          <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index}>
            <Autocomplete
              disabled={params != 0 && stateKey === "companyName"}
              size="small"
              key={index}
              id={`autocomplete-${stateKey}-${index}`}
              options={Array.isArray(options) ? options : fetchedOptions[options] || []}
              getOptionLabel={option => option[getOptionLabelKey]}
              value={(Array.isArray(options) ? options : fetchedOptions[options] || []).find(item => item[valueKey]=== selectedValue) || null}
              renderInput={params => <TextField {...params} label={isTemplateForm?null:label} />}
              onChange={(event, value) => {
                updateInputObject(stateKey, value ? value[valueKey] : '');
              }}
            />
          </Grid>
          </>
        );

      case 'TextField':
        const isDescriptionField = ['description', 'addressOfFactory', 'typeIdentificationNumber', 'numberOfCertification', 'defectDescription', 'renewalInformation', 'addressFactory', 'distinctiveNumber',  'conditionSubject',"renewalsOrAlternations ","ultrasonicTest","qualification"]?.includes(stateKey);


        return (<>
          {isTemplateForm && ( <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index} style={{textAlign:"left"}}>
            <b>{label}</b>
            </Grid>
            )}
          <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index}>
            <TextField
              id={`text-field-${stateKey}-${index}`}
              label={isTemplateForm?null:label}
              size="small"
              fullWidth
              multiline={isDescriptionField}
              rows={isDescriptionField ? 3 : 1}  
              variant="outlined"
              value={selectedValue}
              onChange={e => updateInputObject(stateKey, e.target.value)}
            />
          </Grid>
          </>
        );

      case 'DatePicker':
        return (
          <>
             {isTemplateForm && ( <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index} style={{textAlign:"left"}} >
        <b>{label}</b>
        </Grid>
        )}
          <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index}>
             <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            slotProps={{ textField: { size: "small", fullWidth: true } }}
              label={isTemplateForm?null:label}
              value={selectedValue? new Date(selectedValue) :""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={date => updateInputObject(stateKey, date)}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
          </Grid>
          </>
        );

        case 'ImageUpload':
          return (
            <>
              {isTemplateForm && (
                <Grid item xs={12} sm={6} md={isTemplateForm ? 6 : 4} lg={isTemplateForm ? 6 : 3} key={index} style={{ textAlign: "left" }}>
                  <b>{label}</b>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={isTemplateForm ? 6 : 4} lg={isTemplateForm ? 6 : 3} key={index} style={{ textAlign: "left" }}>
                <Input
                  id={`file-upload-input-${stateKey}-${index}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload12(e, stateKey)}
                />
                <label htmlFor={`file-upload-input-${stateKey}-${index}`}>
                  <Button variant="contained" component="span">
                    Upload {label}
                  </Button>
                </label>
                <Typography variant="body2" color="textSecondary">
                {  `Select a file to upload ${label}`}
                </Typography>
           </Grid>
          </>
        );

        case 'onlyLable':
          return (<>
            {isTemplateForm && ( <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index} style={{textAlign:"left"}}>
              <b>{label}</b>
              </Grid>
              )}
            <Grid item xs={12} sm={6} md={isTemplateForm?6:4} lg={isTemplateForm?6:3} key={index}>
             
            </Grid>
            </>
          );

      default:
        return null; // Handle other types if needed
    }
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
      {inputFields.map(renderInputField)}
    </Grid>
  );
};

export default CommonForm;
