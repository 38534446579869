import React, { useEffect, useRef } from "react";
import stampImage from "../image/BfiStamp.png";
import Logo from "../image/logos.png";
import { Button, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const Sticker = ({ selectedFormID }) => {
  const elementRef = useRef(null);
  const { reportID } = useParams();
  const [newReportID, setNewReportID] = React.useState(
    reportID || selectedFormID
  );
  const [inputStates, setInputStates] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [templateId, setTemplateId] = React.useState(null);

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };
  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];

      setTemplateId(data?.templateId);

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(value) {
    return moment.utc(value, moment.ISO_8601, true).isValid()
      ? moment.utc(value).format("DD/MM/YYYY")
      : value;
  }

  function findDueDate(value, monthsToAdd) {
    const formats = ["DD/MM/YYYY", "DD.MM.YYYY"];
    const parsedDate = moment(value, formats.concat(moment.ISO_8601), true);
  
    if (parsedDate.isValid()) {
      return parsedDate.add(monthsToAdd, "months").format("DD/MM/YYYY");
    }
  
    return value;
  }
  


  useEffect(() => {
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID]);

  return (
    <div
      style={{
        pageBreakInside: "avoid !important",
        width: "100%",
        height: "100%",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          pageBreakInside: "avoid !important",
          marginTop: "20px",
        }}
      >
        <table
          style={{
            height: "100%",
            marginTop: "10px",
            pageBreakInside: "avoid",
          }}
        >
          <tbody id="singlebordertable">
            <tr>
              <td
                style={{
                  width: "100px",
                  padding: "2px",
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                }}
              >
                <img style={{ height: "80px", width: "80%" }} src={Logo} />{" "}
              </td>
              <td
                colSpan={4}
                style={{
                  textAlign: "center",
                  padding: "2px",
                  borderBottom: "none",
                }}
              >
                <div>
                  <b
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    Creative Services
                  </b>
                  <br />
                  <h6
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Kalyan: 113, Blossom Commercial Bldg., Raheja
                    Complex,Kalyan( West ), Dist. Thane. Maharashtra 421301
                    8652233801/ 03 & 9821189331
                  </h6>
                  <hr style={{ margin: "5px 0px" }} />
                  <h6
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Nashik: 02, Shri Sai Apartment, Pakhal Road, Dwarka, Nashik
                    422011 8652233800/02/05
                  </h6>
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {templateId == 12 ? `Type of Testing` : " "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Test Date
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Due Date
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {templateId == 12 ? `External Test` : " Date"}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {formatDate(
                  inputStates[
                    templateId == 12 ? "lastExternalExamination" : "reportDate"
                  ]
                )}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {findDueDate(
                  inputStates[
                    templateId == 12
                      ? "lastExternalExamination"
                      : "reportDate"
                  ], inputStates["testingFrequency"]?.split(" months")?.[0]
                )}
              </td>
            </tr>

            {templateId == 12 ? (
              <>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    Internal Test
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {formatDate(inputStates["internalTestDate"])}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {formatDate(inputStates["internalExaminationDueDate"])}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    Hydraulic Test
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {formatDate(inputStates["hydraulicTestDate"])}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {findDueDate(inputStates["hydraulicTestDate"], 24)}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    Ultrasonic Test
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {formatDate(inputStates["utrasonicTestDate"])}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "10px",
                      padding: "2px",
                    }}
                  >
                    {findDueDate(inputStates["utrasonicTestDate"], 6)}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sticker;
