import { Button, Toolbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Sticker from "./sticker";
import { useLocation } from "react-router-dom";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import html2pdf from "html2pdf.js";

const MultipleStickers = () => {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const elementRef = useRef(null);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = elementRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [8, 1, 10, 1],
          filename: `masterInOut.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: "png", quality: 0.7 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 6;
        const borderX = leftMargin;
        const borderY = 5;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 830);
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `stiker.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  // const handlePrint = () => {
  //   const printContent = document.getElementById("formToPrint");
  //   const originalContents = document.body.innerHTML;

  //   if (printContent) {
  //     document.body.innerHTML = elementRef.current.innerHTML;
  //     window.print();
  //     document.body.innerHTML = originalContents;
  //     window.location.reload();
  //   }
  // };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const idsParam = searchParams.get("ids");
    if (idsParam) {
      const idsArray = idsParam.split(",").map((id) => parseInt(id));
      setIds(idsArray);
    }
  }, [location.search]);

  return (
    <>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{ marginRight: "20px" }}
          onClick={generatePDF}
        >
          Report Print
        </Button>
      </Toolbar>
      <div
        ref={elementRef}
        style={{ width: "95%",  margin: "5px auto" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 330px)",
            gridAutoRows: "250px",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {ids?.map((ele) => {
            return <Sticker selectedFormID={ele} />;
          })}
        </div>
        <br/>
        <br/>
      </div>

      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"Stiker"} />
      )}
    </>
  );
};

export default MultipleStickers;